import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { COUNTRY_LIST } from "../../utils/helpers";
import { LoadingButton } from "@mui/lab";
import { updateClinicProfile } from "../../app/actions";
import { useForm } from "react-hook-form";

import ButtonLink from "../button/ButtonLink";
import CitySelector from "../city_selector/CitySelector";
import FormProvider from "../minimal/hook-form/FormProvider";
import Modal from "../modal/Modal";
import RHFTextField from "../minimal/hook-form/RHFTextField";
import { toast } from "../notifications/Toast";

const clinicSchema = yup.object().shape({
    name: yup.string().required(),
    address1: yup.string().required(),
    state: yup.string(),
    city: yup.string().required(),
    zip: yup.string().required(),
    country: yup.string().required(),
});

const initialValues = {
    name: "",
    address1: "",
    address2: "",
    state: "",
    city: "",
    zip: "",
    country: "",
};

export default function EditClinicInfoModal({ show, clinic, handleClose }) {
    const dispatch = useDispatch();
    const methods = useForm({
        resolver: yupResolver(clinicSchema),
        initialValues,
    });

    const {
        getValues,
        setValue,
        handleSubmit,
        reset,
        formState: { isSubmitting, errors },
    } = methods;

    useEffect(() => {
        reset({
            ...initialValues,
            name: clinic && clinic.title,
            address1: clinic && clinic.address1,
            address2: clinic && clinic.address2,
            city: clinic && clinic.city,
            state: clinic && clinic.state,
            zip: clinic && clinic.zip,
            country: clinic && clinic.country,
        });
    }, [show]); // eslint-disable-line

    const onCountryChange = useCallback(
        (country) => {
            setValue("country", country.target.value, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        },
        [setValue]
    );

    const updateCity = (setValue) => (city) => {
        setValue("city", city.name, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        setValue("state", city.state || city.state_name, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        });
        setValue("country", city.country, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    };

    const handleFormSubmit = async () => {
        const { name, address1, address2, city, country, state, zip } = getValues();
        const body = {
            title: name,
            address1,
            address2,
            city,
            state,
            zip,
            country,
            latitude: null,
            longitude: null,
        };

        const result = await dispatch(updateClinicProfile(clinic.id, body));

        if (result.error) {
            toast.error("Error updating info");
        } else {
            toast.success("Info updated");
            handleClose();
        }
    };

    return (
        <>
            <Modal
                show={show}
                toggleShow={handleClose}
                content={
                    <div>
                        <Typography variant="h6" sx={{ my: 2 }}>
                            Edit Clinic Info
                        </Typography>
                        <FormProvider methods={methods} onSubmit={handleSubmit(handleFormSubmit)}>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                <Grid item xs={12}>
                                    <RHFTextField name="name" label="Clinic Name" />
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFTextField name="address1" label="Address" />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <RHFTextField name="address2" label="Address 2 (optional)" />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <CitySelector
                                        prefillCity={getValues("city")}
                                        handleSelectCity={updateCity(setValue)}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <RHFTextField name="state" label="State/Province/Region" />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <RHFTextField name="zip" label="Zip" />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextField
                                        id="country"
                                        name="country"
                                        label="Country"
                                        sx={{
                                            width: "100%",
                                            "& .MuiOutlinedInput-input": { padding: "8.5px 14px" },
                                            label: {
                                                top: "-7px",
                                            },
                                        }}
                                        SelectProps={{
                                            MenuProps: {
                                                sx: { height: "400px" },
                                            },
                                        }}
                                        select
                                        value={getValues("country")}
                                        defaultValue={getValues("country")}
                                        error={!!errors["country"]}
                                        helperText={errors["country"]?.message}
                                        onChange={onCountryChange}
                                    >
                                        {COUNTRY_LIST &&
                                            COUNTRY_LIST.length > 0 &&
                                            COUNTRY_LIST.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                    sx={{
                                                        mx: 1,
                                                        my: 0.5,
                                                        borderRadius: 0.75,
                                                        typography: "body2",
                                                        textTransform: "capitalize",
                                                    }}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Stack direction="row" justifyContent="end" spacing={2} sx={{ my: 2 }}>
                                <ButtonLink link onClick={handleClose} className="p-2">
                                    Cancel
                                </ButtonLink>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                    className="text-right"
                                >
                                    Save
                                </LoadingButton>
                            </Stack>
                        </FormProvider>
                    </div>
                }
            />
        </>
    );
}
