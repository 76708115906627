import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Box,
    Chip,
    Divider,
    Drawer,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Stack,
    Typography,
    capitalize,
    useTheme,
} from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import { GoogleMap } from "@react-google-maps/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import cx from "classnames";
import * as yup from "yup";
import debounce from "lodash.debounce";

import {
    CANADIAN_PROVINCES,
    DEFAULT_PROVIDER_PAGE_SIZE,
    PROVIDER_TYPES,
    STATES_LIST,
    getPublicImage,
    isMFRGroup,
    parseGoogleAddressComponents,
} from "../../utils/helpers";
import { PROVIDER_CLINIC_PROFILE_ROUTE, PROVIDER_PROFILE_ROUTE, PROVIDER_MAP_RESULTS_ROUTE } from "../../utils/routes";
import {
    getAllCredentials,
    getStateBlurb,
    searchMfrClinicsList,
    searchMfrProviders,
    storeUserLocation,
} from "../../app/actions";
import { useForm } from "react-hook-form";

import Button from "../button/Button";
import ButtonMain from "../button/ButtonMain";
import ButtonLink from "../button/ButtonLink";
import CarouselDots from "../minimal/carousel/CarouselDots";
import EmptyContent from "../minimal/EmptyContent";
import FormProvider from "../minimal/hook-form/FormProvider";
import MFRHeader from "../../layouts/dashboard/header/MFRHeader";
import Page from "../minimal/Page";
import ProviderContactForm from "./ProviderContactForm";
import RHFTextField from "../minimal/hook-form/RHFTextField";
import RHFSelect from "../minimal/hook-form/RHFSelect";
import Slider from "react-slick";
import Spinner from "../spinner/Spinner";
import SvgIconStyle from "../minimal/SvgIconStyle";
import { toast } from "../notifications/Toast";
import TSAvatar from "../avatar/TSAvatar";
import TSMarker from "../marker/TSMarker";
import Iconify from "../minimal/Iconify";
import useResponsive from "../../hooks/useResponsive";

const DEBUGGING_BOUNDING_BOX = localStorage.getItem("DEBUGGING_BOUNDING_BOX") === "true";
const DEBUGGING_BOUNDING_BOX_SIZE = 70;

const providerSearchSchema = yup.object().shape({});

const initialValues = {
    name: "",
    clinic: "",
    address: "",
    provider_type: "",
};

const SORT_OPTIONS = [
    { label: "Distance (Nearest)", value: "distance" },
    { label: "Distance (Furthest)", value: "-distance" },
    { label: "Provider Name (A-Z)", value: "user__first_name,user__last_name" },
    { label: "Provider Name (Z-A)", value: "-user__first_name,-user__last_name" },
    { label: "Clinic Name (A-Z)", value: "title" },
    { label: "Clinic Name (Z-A)", value: "-title" },
    { label: "Providers (Low to High)", value: "total_providers" },
    { label: "Providers (High to Low)", value: "-total_providers" },
];

/**
 * Fetch a place prediction base on the user input.
 *
 * Use case:
 *
 * - When the user does not select an item from the suggestion list get a
 *   prediction based on the current input.
 *
 * @param {google.maps.places.AutocompleteService} service
 * @param {string} input
 * @returns {Promise<google.maps.places.AutocompletePrediction[] | null>}
 */
export function getPlacePredictions(service, input) {
    return new Promise((resolve, reject) => {
        service.getPlacePredictions({ input }, (predictions, status) => {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
                reject(new Error("Failed to get predictions"));
                return;
            }
            resolve(predictions);
        });
    });
}

/**
 * Fetch place prediction details.
 *
 * Use case:
 *
 *  - After getting the place prediction, fetch the place details that we
 *    need from Google Place Service.
 *
 * @param {google.maps.places.PlacesService} service
 * @param {string} placeId
 * @returns {Promise<google.maps.places.PlaceResult>}
 */
export function getPlaceDetails(service, placeId) {
    return new Promise((resolve, reject) => {
        service.getDetails({ placeId, fields: ["address_components", "formatted_address"] }, (place, status) => {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                reject(new Error("Failed to get place details"));
                return;
            }

            resolve(place);
        });
    });
}

/**
 * @param {string} address
 * @returns {string[]}
 */
export function getFormattedAddress(address) {
    const city = address.split(", ")[0];
    const state = address.split(", ")[1];
    return [city, state].filter(Boolean);
}

export default function MapSearchResults() {
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const me = useSelector(({ me }) => me);
    const mfr = useSelector(({ mfr }) => mfr);
    const [addressComponents, setAddressComponents] = useState([]);
    const [contactProvider, setContactProvider] = useState(false);
    const [contactClinic, setContactClinic] = useState(false);
    const [prefillCity, setPrefillCity] = useState("");
    const [prefillState, setPrefillState] = useState("");
    const [prefillCountry, setPrefillCountry] = useState("");
    const [hasMovedMap, setHasMovedMap] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [storedParams, setStoredParams] = useState({});
    const [showSearch, setShowSearch] = useState(false);
    const [mapZoom, setMapZoom] = useState(undefined);
    const [clearedSearch, setClearedSearch] = useState(false);
    const [hasGeocoded, setHasGeocoded] = useState(false);
    const [emptySearch, setEmptySearch] = useState(false);
    const [mobileMapView, setMobileMapView] = useState(false);
    const [resultsHeader, setResultsHeader] = useState("");
    const sortByRef = useRef("distance");
    const [center, setCenter] = useState({ lat: 40.02388015260152, lng: -105.28169680665563 });
    const mapBoundsRef = useRef(null);
    const isDesktop = useResponsive("up", "lg");

    /** @type {React.RefObject<google.maps.Geocoder>} */
    const geocodeRef = useRef(null);
    /** @type {React.RefObject<google.maps.places.Autocomplete>} */
    const autocompleteRef = useRef(null);
    /** @type {React.RefObject<google.maps.places.AutocompleteService>} */
    const googleAutoCompleteServiceRef = useRef(null);
    /** @type {React.RefObject<google.maps.Map>} */
    const mapRef = useRef(null);
    /** @type {React.RefObject<google.maps.Rectangle>} */
    const rectangleRef = useRef(null);
    const currentlyOpenedRef = useRef(null);

    const isLoading = mfr.searchMfrProvidersIsFetching;
    const isMFR = isMFRGroup(me.group);
    const mfrDomain =
        window.location.hostname.includes("mfrtherapists.com") || window.location.hostname.includes("mfrhealth.com");
    const resultsList =
        mfr && mfr.clinics && mfr.clinics.length > 0
            ? mfr.clinics
            : mfr.providers && mfr.providers.length > 0
              ? mfr.providers
              : [];
    const resultsCount = mfr && mfr.clinicCount ? mfr.clinicCount : mfr.providerCount ? mfr.providerCount : 0;

    const containerStyle = {
        width: "100%",
        height: "100%",
    };

    const methods = useForm({
        resolver: yupResolver(providerSearchSchema),
        initialValues,
    });

    const { getValues, setValue, handleSubmit, reset } = methods;
    const filterCount = Object.keys(getValues()).filter((val) => getValues(val)).length - 1;

    useEffect(() => {
        async function init() {
            // Setup address autocomplete
            geocodeRef.current = new window.google.maps.Geocoder();

            autocompleteRef.current = new window.google.maps.places.Autocomplete(
                document.getElementById("google-maps-autocomplete"),
                {
                    fields: ["address_components", "formatted_address"],
                }
            );

            autocompleteRef.current.addListener("place_changed", handlePlaceChange);
            googleAutoCompleteServiceRef.current = new window.google.maps.places.AutocompleteService();

            dispatch(getAllCredentials());

            // Pull search queries for a prefill search
            const {
                location: { search },
            } = window;

            let prefillValues = {};
            let queryFields = qs.parse(search);
            if (queryFields.city) prefillValues.city = queryFields.city;
            if (queryFields.state) {
                prefillValues.state = queryFields.state === "LA" ? "Louisiana" : queryFields.state;
            }
            if (queryFields.name) {
                prefillValues.name = queryFields.name;

                if (!queryFields.city && !queryFields.state && !queryFields.country)
                    if (queryFields.name || prefillValues.name) {
                        sortByRef.current = "user__first_name,user__last_name";
                    } else {
                        sortByRef.current = "provider_name";
                    }
            }
            if (queryFields.clinic) {
                prefillValues.clinic = queryFields.clinic;

                if (!queryFields.city && !queryFields.state && !queryFields.country && !queryFields.name)
                    sortByRef.current = "clinics__title";
            }
            if (queryFields.type) prefillValues.type = queryFields.type;
            if (queryFields.country) {
                prefillValues.country = queryFields.country;

                if (!queryFields.clinic && !queryFields.city && !queryFields.state)
                    if (queryFields.name || prefillValues.name) {
                        sortByRef.current = "user__first_name,user__last_name";
                    } else {
                        sortByRef.current = "provider_name";
                    }
            }
            if (queryFields.use_location) prefillValues.use_location = queryFields.use_location;
            let prefillAddress =
                `${prefillValues.city ? prefillValues.city : ""}` +
                `${prefillValues.state ? `${prefillValues.city ? ", " : ""}${prefillValues.state}` : ""}` +
                `${
                    prefillValues.country
                        ? `${prefillValues.city || prefillValues.state ? ", " : ""}${prefillValues.country}`
                        : ""
                }`;

            if (CANADIAN_PROVINCES.find((p) => p.value === prefillValues.state)) {
                prefillAddress = `${prefillValues.state}, Canada`;
                prefillValues.country = "Canada";
            }

            if (queryFields.city && queryFields.state)
                dispatch(storeUserLocation({ state: queryFields.state, city: queryFields.city }));

            // Set initial values with prefill data
            reset({
                ...initialValues,
                address:
                    prefillAddress && prefillAddress.length > 0
                        ? prefillAddress
                        : prefillValues.country
                          ? prefillValues.country
                          : "",
                name: prefillValues.name ? prefillValues.name : "",
                clinic: prefillValues.clinic ? prefillValues.clinic : "",
                provider_type: prefillValues.type ? prefillValues.type : "",
            });

            setValue("address", prefillAddress);
            setPrefillCity(prefillValues.city);
            setPrefillState(prefillValues.state);
            setPrefillCountry(prefillValues.country);
            setResultsHeader(prefillAddress);

            if (prefillValues && prefillValues.state) dispatch(getStateBlurb(prefillValues.state));

            const components = {};
            if (prefillValues.city) components.locality = prefillValues.city;
            if (prefillValues.state) components.administrative_area_level_1 = prefillValues.state;
            if (!prefillValues.city && !prefillValues.state && prefillValues.country)
                components.country = prefillValues.country;

            const zoomLevel = prefillValues.city
                ? 9
                : prefillValues.state && prefillValues.country
                  ? 5
                  : prefillValues.state
                    ? 7
                    : !prefillValues.state && !prefillValues.city && prefillValues.country
                      ? 4
                      : 9;
            setMapZoom(zoomLevel);
            setAddressComponents(components);

            // Get center point from prefill address
            if (prefillValues && Object.keys(prefillValues).length > 0) {
                if (prefillAddress) {
                    geocodeRef.current.geocode({ address: prefillAddress }, (results, status) => {
                        if (status === "OK") {
                            const { lat, lng } = results[0].geometry.location;

                            setCenter({ lat: lat(), lng: lng() });
                        }
                    });
                }

                const searchParams = {};
                if (prefillValues.city) searchParams.clinic_address = prefillValues.city;
                if (prefillValues.state) searchParams.clinic_state = prefillValues.state;
                if (!prefillValues.city && !prefillValues.state && prefillValues.country)
                    searchParams.country = prefillValues.country;
                if (prefillValues.name) searchParams.name = prefillValues.name;
                if (prefillValues.clinic) searchParams.clinic_title = prefillValues.clinic;
                if (prefillValues.type) searchParams.credentials = prefillValues.type;

                const isUsingLocation = prefillValues.use_location && mobileMapView;
                if (!isUsingLocation) {
                    setStoredParams(searchParams);
                    let searchResults = [];
                    if (searchParams.name || searchParams.credentials) {
                        searchResults = await dispatch(
                            searchMfrProviders({
                                ...searchParams,
                                page_size: DEFAULT_PROVIDER_PAGE_SIZE,
                                page: currentPage,
                                ordering: queryFields.clinic ? "clinics__title" : sortByRef.current,
                            })
                        );
                    } else {
                        searchResults = await dispatch(
                            searchMfrClinicsList({
                                ...searchParams,
                                page_size: DEFAULT_PROVIDER_PAGE_SIZE,
                                page: currentPage,
                                ordering: queryFields.clinic ? "title" : sortByRef.current,
                            })
                        );
                    }
                    const { data } = searchResults;

                    if (data && data.results && data.results.length > 0 && !prefillAddress) {
                        const firstClinic =
                            (data.results[0] &&
                                data.results[0].found_clinics &&
                                data.results[0].found_clinics.length > 0 &&
                                data.results[0].found_clinics[0]) ||
                            data.results[0];
                        const clinicAddress = `${firstClinic.address1 ? firstClinic.address1 : ""} ${
                            firstClinic.address2 ? firstClinic.address2 : ""
                        } ${firstClinic.city ? firstClinic.city : ""}, ${firstClinic.state ? firstClinic.state : ""} ${
                            firstClinic.zipcode ? firstClinic.zipcode : ""
                        }`;
                        geocodeRef.current.geocode({ address: clinicAddress }, (results, status) => {
                            if (status === "OK") {
                                const { lat, lng } = results[0].geometry.location;

                                setCenter({ lat: lat(), lng: lng() });
                            }
                        });
                    }
                }
            } else if (mfr.providers && mfr.providers.length > 0) {
                // Get center point from the first provider
                const clinic =
                    mfr.providers[0] && mfr.providers[0].found_clinics && mfr.providers[0].found_clinics.length > 0
                        ? mfr.providers[0].found_clinics[0]
                        : null;

                const providerAddress = clinic
                    ? `${clinic.address1} ${clinic.address2} ${clinic.city}, ${clinic.state} ${clinic.zipcode}`
                    : null;

                if (providerAddress) {
                    geocodeRef.current.geocode({ address: providerAddress }, (results, status) => {
                        if (status === "OK") {
                            const { lat, lng } = results[0].geometry.location;

                            setCenter({ lat: lat(), lng: lng() });
                        }
                    });
                }
            }
        }

        init();
    }, []); // eslint-disable-line

    const handlePlaceChange = async () => {
        // When user selects a new location from autocomplete, reset the bounding box
        // to the default 70-mile radius square instead of using the map viewport.
        setHasMovedMap(false);

        // Since this handle is meant to search for a "selected" place, we only
        // are going to trigger the search when an items is selected
        const place = autocompleteRef.current.getPlace();
        if (place && place.formatted_address) {
            setValue("address", getFormattedAddress(place.formatted_address).join(", "));
            setAddressComponents(parseGoogleAddressComponents(place.address_components));

            // Skip prediction/details API calls by using place data already returned
            // from the Google Places Autocomplete component.
            submitProviderSearch({ address: place });
        }
    };

    const handleAddressChange = (event) => {
        setAddressComponents(null);
    };

    const requestLocation = () => {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    };

    const successCallback = (position) => {
        setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
        setMapZoom(10);

        // Store position for lead submission form
        geocodeRef.current.geocode(
            { location: { lat: position.coords.latitude, lng: position.coords.longitude } },
            (results, status) => {
                if (status === "OK") {
                    if (results && results.length > 0 && results[0].address_components) {
                        const address = parseGoogleAddressComponents(results[0].address_components);

                        const state = STATES_LIST.find((s) => s.label === address.administrative_area_level_1).value;
                        const city = address.locality ? address.locality : address.administrative_area_level_2;
                        dispatch(storeUserLocation({ state, city }));

                        history.push(
                            `${PROVIDER_MAP_RESULTS_ROUTE}?state=${state}${
                                city ? `&city=${city}` : ""
                            }&use_location=true`
                        );

                        setResultsHeader(city + ", " + state + ", " + address.country);
                    }
                }
            }
        );

        OnDragEnd();
    };

    const errorCallback = (error) => {
        if (error.code === 1) toast.error("You must allow location services to use this feature");
    };

    const onMapLoad = useCallback(
        (map) => {
            mapRef.current = map;

            if (DEBUGGING_BOUNDING_BOX) {
                updateRectanglePosition(center);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [center]
    );

    const toggleMobileMapView = () => {
        setMobileMapView(!mobileMapView);
    };

    const handlePageChange = async (event, newPage) => {
        let searchResults = [];
        if (storedParams.name || storedParams.credentials) {
            searchResults = dispatch(
                searchMfrProviders({
                    ...storedParams,
                    ordering: sortByRef.current,
                    page_size: DEFAULT_PROVIDER_PAGE_SIZE,
                    page: newPage,
                })
            );
        } else {
            searchResults = await dispatch(
                searchMfrClinicsList({
                    ...storedParams,
                    page_size: DEFAULT_PROVIDER_PAGE_SIZE,
                    page: newPage,
                })
            );
        }
        const { data } = searchResults;

        if (storedParams.name && data && data.results && data.results.length > 0) {
            const firstClinic =
                data.results[0] &&
                data.results[0].found_clinics &&
                data.results[0].found_clinics.length > 0 &&
                data.results[0].found_clinics[0];
            const clinicAddress = `${firstClinic.address1 ? firstClinic.address1 : ""} ${
                firstClinic.address2 ? firstClinic.address2 : ""
            } ${firstClinic.city ? firstClinic.city : ""}, ${firstClinic.state ? firstClinic.state : ""} ${
                firstClinic.zipcode ? firstClinic.zipcode : ""
            }`;
            geocodeRef.current.geocode({ address: clinicAddress }, (results, status) => {
                if (status === "OK") {
                    const { lat, lng } = results[0].geometry.location;

                    setCenter({ lat: lat(), lng: lng() });
                }
            });
        }

        setCurrentPage(newPage);
    };

    const handleSortChange = (event) => {
        const value = event.target.value;

        sortByRef.current = value;

        if (hasMovedMap) {
            OnDragEnd();
        } else {
            submitProviderSearch({ sort: value });
            mapBoundsRef.current = null;
        }
    };

    const toggleContactProvider = (provider) => () => {
        const center = mapRef && mapRef.current && mapRef.current.getCenter();

        if (hasMovedMap) {
            geocodeRef.current.geocode({ location: { lat: center.lat(), lng: center.lng() } }, (results, status) => {
                if (status === "OK") {
                    if (results && results.length > 0 && results[0].address_components) {
                        const address = parseGoogleAddressComponents(results[0].address_components);

                        const state = STATES_LIST.find((s) => s.label === address.administrative_area_level_1).value;
                        const city = address.locality ? address.locality : address.administrative_area_level_2;

                        dispatch(storeUserLocation({ state, city }));
                    }
                }
            });
        }

        if (provider) setContactProvider(provider);
    };

    const toggleContactClinic = (clinic) => () => {
        const center = mapRef && mapRef.current && mapRef.current.getCenter();

        if (hasMovedMap) {
            geocodeRef.current.geocode({ location: { lat: center.lat(), lng: center.lng() } }, (results, status) => {
                if (status === "OK") {
                    if (results && results.length > 0 && results[0].address_components) {
                        const address = parseGoogleAddressComponents(results[0].address_components);

                        const state = STATES_LIST.find((s) => s.label === address.administrative_area_level_1).value;
                        const city = address.locality ? address.locality : address.administrative_area_level_2;

                        dispatch(storeUserLocation({ state, city }));
                    }
                }
            });
        }

        if (clinic) setContactClinic(clinic);
    };

    const closeContactProvider = () => {
        if (contactProvider) setContactProvider(false);
        if (contactClinic) setContactClinic(false);
    };

    const OnDragStart = () => {
        if (!hasMovedMap) setHasMovedMap(true);
    };

    const OnDragEnd = debounce(async () => {
        const currentMapZoom = mapRef.current.getZoom();
        const hasZoomChanged = currentMapZoom !== mapZoom;

        if (hasMovedMap === false) {
            if (hasZoomChanged === false) {
                // No changes detected in map position or zoom level
                return;
            } else {
                // Map zoom level has changed but position remains the same, so mark map as moved
                setHasMovedMap(true);
            }
        }

        if (hasGeocoded) {
            setHasGeocoded(false);
            return;
        }

        if (clearedSearch) {
            setClearedSearch(false);
            return;
        }

        if (emptySearch) {
            setEmptySearch(false);
            setClearedSearch(false);
            return;
        }

        if (!!mfr.searchMfrProvidersIsFetching) {
            return;
        }

        const bounds = mapRef?.current?.getBounds();
        const ne = mapRef && bounds.getNorthEast();
        const sw = mapRef && bounds.getSouthWest();

        if (!ne && !sw) {
            return;
        }

        if (DEBUGGING_BOUNDING_BOX) {
            const newCenter = mapRef?.current?.getCenter();
            if (newCenter) {
                setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
            }
        }

        const params = {};
        // The map bounds
        if (!isDesktop && !mobileMapView && mapBoundsRef.current) {
            Object.assign(params, mapBoundsRef.current);
        } else {
            params.bottom_left_lat = sw.lat();
            params.bottom_left_long = sw.lng();
            params.top_right_lat = ne.lat();
            params.top_right_long = ne.lng();
        }

        // Cache the current map bounds for backend sorting of clinics by proximity.
        // This caching is necessary because when users toggle from map view to list view,
        // the map height is set to zero, making it impossible to retrieve the bounds
        // directly during subsequent search operations.
        //
        // These cached bounds allow the backend to properly calculate distances and
        // sort results by proximity even when the map is not visible.
        //
        // TODO: Refactor this approach when implementing a better UI pattern for
        // toggling between map and list views.
        mapBoundsRef.current = { ...params };

        // Current sort value
        params.ordering = sortByRef.current;

        // Display pins
        const { name, clinic, provider_type } = getValues();
        if (name) params.name = name;
        if (clinic) params.clinic_title = clinic;
        if (provider_type && provider_type.length > 0) {
            const credentials = provider_type;
            params.credentials = credentials;
        }

        setStoredParams(params);
        let searchResults = [];
        if (params.name || params.credentials) {
            searchResults = await dispatch(
                searchMfrProviders({
                    ...params,
                    page_size: DEFAULT_PROVIDER_PAGE_SIZE,
                    page: currentPage,
                })
            );
        } else {
            searchResults = await dispatch(
                searchMfrClinicsList({
                    ...params,
                    page_size: DEFAULT_PROVIDER_PAGE_SIZE,
                    page: currentPage,
                })
            );
        }

        const { error } = searchResults;
        if (error) {
            toast.error("There was an error searching for providers");
        }
    }, 500);

    const handleSearchOpen = () => {
        setShowSearch(true);
    };

    const handleSearchClose = () => {
        setShowSearch(false);
    };

    const handleClearSearch = async () => {
        history.push(PROVIDER_MAP_RESULTS_ROUTE);
        setAddressComponents({});
        setStoredParams({});
        setPrefillCity("");
        setPrefillState("");
        setPrefillCountry("");
        reset(initialValues);
        setClearedSearch(true);
    };

    /**
     * @param {Object} params
     * @param {string} [params.sort]
     * @param {(google.maps.places.PlaceResult|string)} [params.address]
     */
    const submitProviderSearch = async ({ sort = "", address: addressInputValue } = {}) => {
        if (showSearch) setShowSearch(false);

        let place = {};
        let componentFromPrediction = [];
        // Uses the Google Places API to retrieve address autocomplete
        // predictions matching the user's input
        if (typeof addressInputValue === "string" && addressInputValue.length > 0) {
            const googleAutoCompleteService = googleAutoCompleteServiceRef.current;
            const predictions = await getPlacePredictions(googleAutoCompleteService, addressInputValue);
            const prediction = predictions.at(0);

            const googlePlaceDetailsService = new window.google.maps.places.PlacesService(mapRef.current);
            const placeDetails = await getPlaceDetails(googlePlaceDetailsService, prediction.place_id);
            place = placeDetails;

            setValue("address", getFormattedAddress(place.formatted_address).join(", "));

            componentFromPrediction = parseGoogleAddressComponents(placeDetails.address_components);
            setAddressComponents(componentFromPrediction);
        }

        let pageOverride = null;
        const { address, clinic, name, provider_type } = getValues();

        let components =
            addressComponents && Object.keys(addressComponents).length > 0
                ? addressComponents
                : place && place.address_components
                  ? parseGoogleAddressComponents(place.address_components)
                  : { locality: address || null };

        // Use the address component from the prediction if we have one
        if (Object.values(componentFromPrediction).length > 0) {
            components = componentFromPrediction;
        }

        // TODO: this doesn't make sense
        if (!address && components) components = {};

        const params = {};
        if (name) params.name = name;
        if (clinic) params.clinic_title = clinic;
        if (provider_type && provider_type.length > 0) {
            params.credentials = provider_type;
        }

        if (sort && typeof sort === "string") {
            params.ordering = sort;
            pageOverride = 1;
            setCurrentPage(1);
        } else {
            params.ordering = sortByRef.current;
            pageOverride = null;
        }

        if (components && components.administrative_area_level_1) {
            const state = STATES_LIST.find(
                (s) =>
                    s.label === components.administrative_area_level_1 ||
                    s.value === components.administrative_area_level_1
            );
            const province = CANADIAN_PROVINCES.find(
                (p) =>
                    p.value === components.administrative_area_level_1 ||
                    p.label === components.administrative_area_level_1
            );

            params.clinic_state = state ? state.value : province ? province.value : address.split(", ")[1];

            if (params.clinic_state === "us" || params.clinic_state === "ca") delete params.clinic_state;
        } else if (prefillState) {
            params.clinic_state = prefillState;
        } else if (address) {
            params.clinic_state = address.split(", ")[0];
        }

        if (components && components.locality) {
            params.clinic_city = components.locality;
        } else if (prefillCity && address) {
            params.clinic_address = prefillCity;
        } else if (address && address.length > 0 && components && components.length === 0) {
            params.clinic_address = address;
        } else if (address && address.length > 0 && params.name && params.name.length > 0) {
            if (!address.includes(",")) params.clinic_address = address;
        }
        params.country = components?.country;

        setResultsHeader(
            params.clinic_address ? params.clinic_address : params.clinic_state ? params.clinic_state : ""
        );

        // If not params were computed set the map to the center of the US, as
        // the default position.
        if (params && Object.keys(params).length === 0) {
            setEmptySearch(true);
            setCenter({ lat: 39.8097343, lng: -98.5556199 });
            setMapZoom(4);
        }

        setStoredParams(params);

        let searchResult = [];
        if (params.name || params.credentials) {
            searchResult = await dispatch(
                searchMfrProviders({
                    ...params,
                    page_size: DEFAULT_PROVIDER_PAGE_SIZE,
                    page: pageOverride ? pageOverride : currentPage,
                })
            );
        } else {
            searchResult = await dispatch(
                searchMfrClinicsList({
                    ...params,
                    page_size: DEFAULT_PROVIDER_PAGE_SIZE,
                    page: pageOverride ? pageOverride : currentPage,
                })
            );
        }

        const { error } = searchResult;

        if (error) {
            toast.error("There was an error searching for providers");
        } else if (Object.keys(params).length > 0) {
            setHasGeocoded(true);
            setResultsHeader(address);

            const [city, state] = getFormattedAddress(address);

            const searchParams = new URLSearchParams();
            if (state?.length) {
                searchParams.set("state", state);
            }
            if (city?.length) {
                searchParams.set(city.length === 2 ? "country" : "city", city);
            }

            if (searchParams.toString().length) {
                history.push(`${PROVIDER_MAP_RESULTS_ROUTE}?${searchParams.toString()}`);

                geocodeRef.current.geocode({ address }, (results, status) => {
                    if (status === "OK") {
                        const { lat, lng } = results[0].geometry.location;

                        setCenter({ lat: lat(), lng: lng() });
                        setMapZoom(params && params.clinic_address ? 7 : 9);
                    }
                });
                setEmptySearch(false);
            }
        } else {
            setEmptySearch(false);
        }
    };

    const CustomArrow = ({ className, style, onClick, direction }) => {
        const arrows = {
            left: "eva:arrow-left-fill",
            right: "eva:arrow-right-fill",
        };
        return (
            <Box
                component="button"
                style={{
                    ...style,
                    p: 0,
                    border: 0,
                    width: 32,
                    height: 32,
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                onClick={onClick}
            >
                <Iconify icon={arrows[direction]} />
            </Box>
        );
    };

    const ProviderCard = ({ provider, idx }) => {
        const settings = {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            lazyLoad: "anticipated",
            speed: 500,
            swipe: false,
            dots: false,
            prevArrow: <CustomArrow direction="left" />,
            nextArrow: <CustomArrow direction="right" />,
            ...CarouselDots(),
        };

        const carouselRef = useRef(null);

        const providerProfileComponent = (
            <>
                <TSAvatar image={provider.user && provider.photo} name={provider.user && provider.user.first_name} />
                {provider && provider.expertise_level && (
                    <Box sx={{ maxHeight: "90px", mt: 1 }}>
                        <a
                            href={`${generatePath(PROVIDER_PROFILE_ROUTE, { providerId: provider.id })}/${provider.slug}`}
                            rel="canonical"
                            title={`${provider.user.first_name} ${provider.user.last_name}'s profile`}
                        >
                            <Chip
                                label={capitalize(provider.expertise_level)}
                                sx={{
                                    fontWeight: 600,
                                    backgroundColor: theme.palette.slate.main,
                                    color: theme.palette.common.white,
                                }}
                            />
                        </a>
                    </Box>
                )}
            </>
        );

        const providerInfoComponent = (
            <>
                <Stack
                    sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Box>
                        <a
                            href={`${generatePath(PROVIDER_PROFILE_ROUTE, { providerId: provider.id })}/${provider.slug}`}
                            rel="canonical"
                            title={`${provider.user.first_name} ${provider.user.last_name}'s profile`}
                        >
                            <Typography variant="h6" sx={{ color: theme.palette.grey[800] }}>
                                {provider.user.first_name} {provider.user.last_name}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ color: theme.palette.grey[600], maxWidth: "175px" }}
                                className="overflow-text"
                            >
                                {provider.title}
                            </Typography>
                        </a>
                    </Box>
                    <Box sx={{ zIndex: 1000 }}>
                        <ButtonMain onClick={toggleContactProvider(provider)}>Contact</ButtonMain>
                    </Box>
                </Stack>
                <Grid container sx={{ mt: 0.5, ml: 0, gap: 1 }}>
                    {provider &&
                        provider.profile_credentials &&
                        provider.profile_credentials.length > 0 &&
                        provider.profile_credentials.map((cred, idx) => (
                            <Grid item key={idx}>
                                <a
                                    href={`${generatePath(PROVIDER_PROFILE_ROUTE, {
                                        providerId: provider.id,
                                    })}/${provider.slug}`}
                                    rel="canonical"
                                    title={`${provider.user.first_name} ${provider.user.last_name}'s profile`}
                                >
                                    {cred && cred.credential && (
                                        <Grid item>
                                            <Chip
                                                label={cred.credential}
                                                size="small"
                                                sx={{ fontWeight: 600, mt: "0px !important" }}
                                            />
                                        </Grid>
                                    )}
                                </a>
                            </Grid>
                        ))}
                </Grid>
                <Box sx={{ maxHeight: "90px", mt: 2 }}>
                    <a
                        href={`${generatePath(PROVIDER_PROFILE_ROUTE, { providerId: provider.id })}/${provider.slug}`}
                        rel="canonical"
                        title={`${provider.user.first_name} ${provider.user.last_name}'s profile`}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                fontWeight: 400,
                                color: theme.palette.grey[700],
                                wordBreak: "break-all",
                            }}
                            className="ts-provider-card-bio"
                        >
                            {provider.bio}
                        </Typography>
                    </a>
                </Box>
            </>
        );

        const providerClinicsComponents = (
            <>
                {provider && provider.found_clinics && provider.found_clinics.length > 1 ? (
                    <Slider ref={carouselRef} {...settings} className="ts-clinic-carousel">
                        {provider &&
                            provider.found_clinics &&
                            provider.found_clinics.length > 0 &&
                            provider.found_clinics.map((clinic, idx) => (
                                <Box key={idx}>
                                    <Typography variant="Body2" sx={{ fontWeight: 600 }}>
                                        {clinic.title}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: theme.palette.grey[600] }}>
                                        {clinic.address1} {clinic.address2} {clinic.city}, {clinic.state} {clinic.zip}
                                    </Typography>
                                    <Box>
                                        <ButtonLink
                                            href={`${generatePath(PROVIDER_CLINIC_PROFILE_ROUTE, {
                                                clinicId: clinic.id,
                                            })}/${clinic.slug}`}
                                            rel="canonical"
                                            title={`${clinic.title}'s profile`}
                                        >
                                            View {clinic.title}'s profile
                                        </ButtonLink>
                                    </Box>
                                </Box>
                            ))}
                    </Slider>
                ) : (
                    <>
                        {provider && provider.found_clinics && provider.found_clinics.length > 0 && (
                            <Stack>
                                <Typography variant="Body2" sx={{ fontWeight: 600 }}>
                                    {provider.found_clinics[0].title}
                                </Typography>
                                <Typography variant="subtitle2" sx={{ color: theme.palette.grey[600] }}>
                                    {provider.found_clinics[0].address1} {provider.found_clinics[0].address2}{" "}
                                    {provider.found_clinics[0].city}, {provider.found_clinics[0].state}{" "}
                                    {provider.found_clinics[0].zip}
                                </Typography>
                                <Box>
                                    <ButtonLink
                                        href={`${generatePath(PROVIDER_CLINIC_PROFILE_ROUTE, {
                                            clinicId: provider.found_clinics[0].id,
                                        })}/${provider.found_clinics[0].slug}`}
                                        rel="canonical"
                                        title={`${provider.found_clinics[0].title}'s profile`}
                                    >
                                        View {provider.found_clinics[0].title}'s profile
                                    </ButtonLink>
                                </Box>
                            </Stack>
                        )}
                    </>
                )}
            </>
        );
        return (
            <Box key={idx}>
                {idx > 0 ? <Divider /> : null}
                <Box
                    className="hover-pointer"
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "minmax(65px, 100px) 1fr",
                        gridTemplateRows: "repeat(2, auto)",
                        gridTemplateAreas: {
                            xs: `"avatar info"
                                 "slide slide"`,
                            sm: `"avatar info"
                                 "avatar slide"`,
                        },
                        gap: 2,
                        p: 2,
                    }}
                >
                    <Box
                        sx={{
                            gridArea: "avatar",
                            justifyItems: "center",
                            minWidth: { sm: "100px" },
                        }}
                    >
                        {providerProfileComponent}
                    </Box>
                    <Box
                        sx={{
                            gridArea: "info",
                        }}
                    >
                        {providerInfoComponent}
                    </Box>
                    <Box
                        sx={{
                            gridArea: "slide",
                        }}
                    >
                        <Divider sx={{ mb: 1, display: { xs: "none", sm: "block" } }} />
                        <Box
                            sx={{
                                height: "100%",
                                alignContent: "center",
                            }}
                        >
                            {providerClinicsComponents}
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    };

    const ClinicCard = ({ clinic, idx }) => {
        return (
            <div key={idx} className="hover-pointer">
                <Stack sx={{ flexDirection: "row", p: 2 }}>
                    <Stack sx={{ ml: 2, flex: 1, width: { lg: "100%" } }}>
                        <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <Box>
                                <a
                                    href={`${generatePath(PROVIDER_CLINIC_PROFILE_ROUTE, {
                                        clinicId: clinic.id,
                                    })}/${clinic.slug}`}
                                    rel="canonical"
                                    title={`${clinic.title}'s profile`}
                                >
                                    <Typography variant="h6" sx={{ color: theme.palette.grey[800] }}>
                                        {clinic.title}
                                    </Typography>
                                </a>
                                <Typography variant="subtitle2" sx={{ color: theme.palette.grey[600] }}>
                                    {clinic.address1} {clinic.address2} {clinic.city}, {clinic.state} {clinic.zip}
                                </Typography>
                            </Box>
                            <Box sx={{ zIndex: 1000 }}>
                                <ButtonMain onClick={toggleContactClinic(clinic)}>Contact</ButtonMain>
                            </Box>
                        </Stack>
                        <Box sx={{ maxHeight: "90px", mt: 2 }}>
                            <a
                                href={`${generatePath(PROVIDER_CLINIC_PROFILE_ROUTE, {
                                    clinicId: clinic.id,
                                })}/${clinic.slug}`}
                                rel="canonical"
                                title={`${clinic.title}'s profile`}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: 400,
                                        color: theme.palette.grey[700],
                                        wordbreak: "break-all",
                                    }}
                                    className="ts-provider-card-bio"
                                >
                                    {clinic.bio}
                                </Typography>
                            </a>
                        </Box>
                    </Stack>
                </Stack>
                <Divider />
            </div>
        );
    };

    /**
     * Handles opening a marker on the map and closes any previously opened info
     * window
     *
     * @param {Object} actions - The actions object containing methods to control the marker
     * @param {() => void} actions.closeFn - Callback function to close the currently open InfoWindow
     */
    const handleMarkerOpen = (actions) => {
        if (currentlyOpenedRef.current) {
            currentlyOpenedRef.current.closeFn();
        }
        currentlyOpenedRef.current = actions;
    };

    const updateRectanglePosition = (newCenter) => {
        // Convert miles to degrees
        const distanceDegrees = DEBUGGING_BOUNDING_BOX_SIZE / 69.0;

        /**
         * Bounding box reference between frontend and backend
         *
         * south -> lat_min
         * north -> lat_max
         * east  -> lng_max
         * west  -> lng_min
         */
        const offsetLng = distanceDegrees / Math.abs(Math.cos(newCenter.lat * (Math.PI / 180)));
        const bounds = {
            north: newCenter.lat + distanceDegrees,
            south: newCenter.lat - distanceDegrees,
            east: newCenter.lng + offsetLng,
            west: newCenter.lng - offsetLng,
        };

        if (rectangleRef.current) {
            // If the map was moved, update the bounding box to match the
            // current map viewport
            if (hasMovedMap && mapRef?.current) {
                const newBounds = mapRef.current.getBounds();
                rectangleRef.current.setBounds(newBounds);
                return;
            }

            // Update existing rectangle
            rectangleRef.current.setBounds(
                new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(bounds.south, bounds.west),
                    new window.google.maps.LatLng(bounds.north, bounds.east)
                )
            );
        } else if (mapRef.current) {
            // Create rectangle if it doesn't exist
            rectangleRef.current = new window.google.maps.Rectangle({
                bounds: bounds,
                map: mapRef.current,
                strokeColor: "#0000FF",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#0000FF",
                fillOpacity: 0.1,
            });
        }
    };

    useEffect(() => {
        if (mapRef?.current && DEBUGGING_BOUNDING_BOX) {
            // Update rectangle when center changes
            updateRectanglePosition(center);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [center]);

    return (
        <Page
            title={`Myofascial Release Therapy ${
                prefillState ? `In ${prefillState}` : prefillCountry ? `In ${prefillCountry}` : ""
            } | MFR Therapists`}
            mfr={mfrDomain}
            mfrProvider={isMFR && me.is_coach}
        >
            {me && !me.id && <MFRHeader />}
            <Stack sx={{ maxHeight: "100%", paddingTop: me && !me.id && "80px" }}>
                <Typography variant="h1" sx={{ fontSize: "1rem !important", mb: 1, ml: 4, mr: 2 }}>
                    {resultsHeader ? `Results for Myofascial Release Therapists in ${resultsHeader}` : "Search Results"}
                </Typography>
                <Stack sx={{ p: 2, borderBottom: "1px solid black" }}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(submitProviderSearch)}>
                        <Grid container sx={{ width: "100%", mt: 0, ml: 0, mb: mfr && mfr.stateContentBlurb ? 2 : 0 }}>
                            <Grid
                                item
                                xs={12}
                                lg={3}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                    justifyContent: "space-between",
                                    p: "0 !important",
                                }}
                            >
                                <Box sx={{ width: "100%" }}>
                                    <Stack
                                        sx={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%",
                                        }}
                                    >
                                        <RHFTextField
                                            id="google-maps-autocomplete"
                                            name="address"
                                            placeholder=""
                                            label="Search by Country, state, city, or zip"
                                            sx={{ mr: { xs: 0, lg: 2 } }}
                                            onFocus={handleAddressChange}
                                        />
                                        <Box sx={{ display: { xs: "block", lg: "none" } }}>
                                            <Button
                                                link
                                                sx={{ minWidth: "100px", ml: 2 }}
                                                className="ml-2 px-2"
                                                onClick={toggleMobileMapView}
                                            >
                                                <Typography variant="caption">
                                                    {mobileMapView ? "View List" : "View Map"}
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Stack>
                                    <Box
                                        sx={{
                                            minWidth: "150px",
                                            display: { xs: "none", lg: "flex" },
                                            alignItems: "center",
                                            px: 1,
                                            mt: 1,
                                        }}
                                    >
                                        <ButtonLink link onClick={requestLocation}>
                                            <SvgIconStyle src={getPublicImage("ic_location.svg")} />
                                            <Typography variant="caption">Use my location</Typography>
                                        </ButtonLink>
                                    </Box>
                                </Box>
                                <Typography
                                    variant="h5"
                                    sx={{ justifySelf: "end", display: { xs: "none", lg: "block" }, ml: 2, mt: 2 }}
                                >
                                    {resultsCount ? resultsCount : 0}{" "}
                                    {storedParams.name || storedParams.credentials ? "Providers" : "Clinics"}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                lg={3}
                                sx={{
                                    pt: "0 !important",
                                    pl: { xs: 0, lg: 2 },
                                    mt: { xs: 2, lg: 0 },
                                    display: { xs: "none", lg: "flex" },
                                    alignItems: "start",
                                    justifyContent: "start",
                                }}
                            >
                                <RHFTextField name="name" label="Provider Name" />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                lg={3}
                                sx={{
                                    pt: "0 !important",
                                    display: { xs: "none", lg: "flex" },
                                    flexDirection: "row",
                                    px: { xs: 1, lg: 2 },
                                    mt: { xs: 2, lg: 0 },
                                    alignItems: "start",
                                    justifyContent: "start",
                                }}
                            >
                                <RHFTextField name="clinic" label="Clinic Name" />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={3}
                                sx={{
                                    display: { xs: "none", lg: "flex" },
                                    flexDirection: "column",
                                    justifyContent: "start",
                                    mt: { xs: 2, lg: 0 },
                                }}
                            >
                                <Stack
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "start",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box>
                                        <ButtonMain outlined onClick={handleSearchOpen}>
                                            Filters
                                        </ButtonMain>
                                        <ButtonLink
                                            disabled={isLoading}
                                            sx={{ py: "6px", px: 2, ml: 1 }}
                                            onClick={handleClearSearch}
                                        >
                                            Clear
                                        </ButtonLink>
                                    </Box>
                                    <Button square secondary type="submit" disabled={isLoading}>
                                        Search
                                    </Button>
                                </Stack>
                                <Stack sx={{ flexDirection: "row", mt: 2 }}>
                                    <FormControl>
                                        <InputLabel id="sort">Sort By</InputLabel>
                                        <Select
                                            name="sort"
                                            label="Sort By"
                                            variant="outlined"
                                            size="small"
                                            value={sortByRef.current}
                                            onChange={handleSortChange}
                                            fullWidth
                                            SelectProps={{ native: false, sx: { textTransform: "capitalize" } }}
                                            sx={{ mr: { xs: 0, lg: 2 }, minWidth: "220px" }}
                                        >
                                            {SORT_OPTIONS.map((option) => {
                                                let disabled = false;
                                                if (
                                                    prefillCountry &&
                                                    prefillCountry.length > 0 &&
                                                    addressComponents &&
                                                    !addressComponents.locality &&
                                                    !addressComponents.administrative_area_level_1 &&
                                                    (option.value === "distance" || option.value === "-distance")
                                                )
                                                    disabled = true;

                                                return (
                                                    <MenuItem
                                                        key={option.value}
                                                        value={option.value}
                                                        disabled={disabled}
                                                    >
                                                        <ListItemText primary={option.label} />
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            // spacing={2}
                            sx={{
                                display: { xs: "flex", lg: "none" },
                                mb: mfr && mfr.stateContentBlurb ? 2 : 0,
                                mt: 1,
                            }}
                        >
                            <Grid item xs={12} sm={4}>
                                <Stack
                                    sx={{
                                        minWidth: "150px",
                                        flexDirection: "column",
                                        alignItems: "start",
                                        justifyContent: "space-between",
                                        height: "100%",
                                        width: "100%",
                                        mr: 2,
                                    }}
                                >
                                    <ButtonLink link onClick={requestLocation}>
                                        <SvgIconStyle src={getPublicImage("ic_location.svg")} />
                                        <Typography variant="caption">Use my location</Typography>
                                    </ButtonLink>
                                </Stack>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={8}
                                sx={{ display: "flex", alignItems: "end", justifyContent: "end" }}
                            >
                                <Stack sx={{ flexDirection: "row", mt: 2, mr: 2 }}>
                                    <RHFSelect
                                        name="sort"
                                        label="Sort By"
                                        SelectProps={{ native: false, sx: { textTransform: "capitalize" } }}
                                        sx={{ mr: { xs: 0, lg: 2 }, minWidth: "220px" }}
                                        value={sortByRef.current}
                                        onChange={handleSortChange}
                                    >
                                        {SORT_OPTIONS.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                <ListItemText primary={option.label} />
                                            </MenuItem>
                                        ))}
                                    </RHFSelect>
                                </Stack>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <ButtonMain outlined onClick={handleSearchOpen}>
                                        Filters {`${filterCount && filterCount > 0 ? `(${filterCount})` : ""}`}
                                    </ButtonMain>
                                </Box>
                            </Grid>
                            <Drawer
                                disableEnforceFocus
                                anchor="right"
                                open={showSearch}
                                onClose={handleSearchClose}
                                PaperProps={{
                                    sx: { width: { xs: "100%", sm: "50%" }, p: 3 },
                                }}
                            >
                                <RHFTextField
                                    name="name"
                                    label="Provider Name"
                                    sx={{ display: { xs: "block", lg: "none" }, my: 2 }}
                                />
                                <RHFTextField
                                    name="clinic"
                                    label="Clinic Name"
                                    sx={{ display: { xs: "block", lg: "none" }, mb: 2 }}
                                />
                                <RHFSelect
                                    name="provider_type"
                                    label="Provider Type"
                                    defaultValue=""
                                    SelectProps={{ native: false, sx: { textTransform: "capitalize" } }}
                                    sx={{ mr: { xs: 0, lg: 2 } }}
                                >
                                    <MenuItem key={"empty-provider-type"} value={""}>
                                        <ListItemText primary="Provider Type" sx={{ color: theme.palette.grey[500] }} />
                                    </MenuItem>
                                    {PROVIDER_TYPES.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            <ListItemText primary={option.label} />
                                        </MenuItem>
                                    ))}
                                </RHFSelect>
                                <Stack sx={{ width: "100%", alignItems: "center", mt: 4 }}>
                                    <ButtonMain fullWidth disabled={isLoading} onClick={submitProviderSearch}>
                                        Search
                                    </ButtonMain>
                                    <Box sx={{ mt: 2, width: "100%" }}>
                                        <ButtonMain fullWidth outlined onClick={handleClearSearch}>
                                            Clear filters
                                        </ButtonMain>
                                    </Box>
                                    <ButtonLink sx={{ mt: 2 }} onClick={handleSearchClose}>
                                        Back to results
                                    </ButtonLink>
                                </Stack>
                            </Drawer>
                        </Grid>
                    </FormProvider>
                </Stack>
                <Stack
                    sx={{
                        flexDirection: { xs: "column", lg: "row" },
                    }}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            display: { xs: mobileMapView ? "none" : "block", lg: "block" },
                        }}
                        className={cx(mobileMapView ? "ts-provider-results-map" : "ts-provider-results-list")}
                    >
                        {resultsCount && currentPage ? (
                            <Stack
                                sx={{
                                    display: { xs: "flex", lg: "none" },
                                    position: "sticky",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    backgroundColor: "white",
                                    zIndex: "1001",
                                    width: { xs: "100%", lg: "550px" },
                                    top: 0,
                                    py: 2,
                                }}
                            >
                                <Typography variant="h5" sx={{ px: 2, minWidth: "100px" }}>
                                    {resultsCount ? resultsCount : 0}{" "}
                                    {storedParams.name || storedParams.credentials ? "Providers" : "Clinics"}
                                </Typography>
                                <Stack
                                    sx={{
                                        flexDirection: "row",
                                        justifyContent: "end",
                                        alignItems: "center",
                                        minWidth: "200px",
                                    }}
                                >
                                    <Typography variant="body2" className="mr-2">
                                        {`${
                                            currentPage > 1
                                                ? currentPage * DEFAULT_PROVIDER_PAGE_SIZE -
                                                  (DEFAULT_PROVIDER_PAGE_SIZE - 1)
                                                : 1
                                        } - ${
                                            mfr.next ? currentPage * DEFAULT_PROVIDER_PAGE_SIZE : resultsCount
                                        } of ${resultsCount}`}
                                    </Typography>
                                    <Pagination
                                        page={currentPage}
                                        count={Math.ceil(resultsCount / DEFAULT_PROVIDER_PAGE_SIZE)}
                                        onChange={handlePageChange}
                                        siblingCount={0}
                                    />
                                </Stack>
                            </Stack>
                        ) : null}
                        {mfr.searchMfrProvidersIsFetching ? (
                            <Stack sx={{ minHeight: "400px", alignItems: "center", justifyContent: "center" }}>
                                <Spinner />
                            </Stack>
                        ) : (
                            <>
                                {storedParams.name || storedParams.credentials ? (
                                    <>
                                        {mfr.providers && mfr.providers.results && mfr.providers.results.length > 0 ? (
                                            mfr.providers.results.map((provider, idx) => (
                                                <ProviderCard provider={provider} idx={idx} key={idx} />
                                            ))
                                        ) : (
                                            <Stack
                                                sx={{
                                                    minHeight: { xs: "225px", lg: "400px" },
                                                    height: { xs: "100%", lg: "400px" },
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    p: 0,
                                                }}
                                            >
                                                <EmptyContent
                                                    title="No providers"
                                                    sx={{
                                                        "& span.MuiBox-root": { height: 100, mb: 2 },
                                                        p: 0,
                                                        minWidth: "600px",
                                                    }}
                                                />
                                            </Stack>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {resultsList && resultsList.length > 0 ? (
                                            resultsList.map((clinic, idx) => (
                                                <ClinicCard clinic={clinic} idx={idx} key={idx} />
                                            ))
                                        ) : (
                                            <Stack
                                                sx={{
                                                    minHeight: { xs: "225px", lg: "400px" },
                                                    height: { xs: "100%", lg: "400px" },
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    p: 0,
                                                }}
                                            >
                                                <EmptyContent
                                                    title="No providers"
                                                    sx={{
                                                        "& span.MuiBox-root": { height: 100, mb: 2 },
                                                        p: 0,
                                                        minWidth: "600px",
                                                    }}
                                                />
                                            </Stack>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {resultsCount && currentPage ? (
                            <Stack
                                sx={{
                                    display: { xs: "none", lg: "flex" },
                                    flexDirection: "row",
                                    justifyContent: "end",
                                    alignItems: "center",
                                    backgroundColor: "white",
                                    width: "600px",
                                    zIndex: "1001",
                                    bottom: 0,
                                    p: 2,
                                }}
                            >
                                <Typography variant="body2" className="mr-2">
                                    {`${
                                        currentPage > 1
                                            ? currentPage * DEFAULT_PROVIDER_PAGE_SIZE -
                                              (DEFAULT_PROVIDER_PAGE_SIZE - 1)
                                            : 1
                                    } - ${
                                        mfr.next ? currentPage * DEFAULT_PROVIDER_PAGE_SIZE : resultsCount
                                    } of ${resultsCount}`}
                                </Typography>
                                <Pagination
                                    page={currentPage}
                                    count={Math.ceil(resultsCount / DEFAULT_PROVIDER_PAGE_SIZE)}
                                    onChange={handlePageChange}
                                />
                            </Stack>
                        ) : (
                            <></>
                        )}
                    </Paper>
                    <Stack
                        as="aside"
                        sx={{
                            position: "sticky",
                            top: 80,
                            height: { xs: mobileMapView ? "93vh" : "0vh", lg: "93vh" },
                            maxHeight: "93vh",
                            width: "100%",
                        }}
                    >
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            zoom={mapZoom}
                            center={center}
                            onLoad={onMapLoad}
                            onDragStart={OnDragStart}
                            onDragEnd={OnDragEnd}
                            onZoomChanged={OnDragEnd}
                        >
                            {mfr.pins &&
                                Object.keys(mfr.pins).length > 0 &&
                                Object.keys(mfr.pins).map((pinId, idx) => (
                                    <TSMarker
                                        clinic={mfr.pins[pinId] && mfr.pins[pinId]}
                                        idx={idx}
                                        key={idx}
                                        onOpen={handleMarkerOpen}
                                    />
                                ))}
                        </GoogleMap>
                    </Stack>
                </Stack>
                {(contactProvider || contactClinic) && (
                    <ProviderContactForm
                        show={Boolean(contactProvider || contactClinic)}
                        provider={contactProvider}
                        clinic={contactClinic}
                        toggleShow={closeContactProvider}
                    />
                )}
                {mfr && mfr.stateContentBlurb && (
                    <Stack sx={{ px: 4, py: 2, minHeight: "105px", maxHeight: "105px", overflow: "auto" }}>
                        <Typography variant="body1">{mfr.stateContentBlurb}</Typography>
                    </Stack>
                )}
            </Stack>
        </Page>
    );
}
